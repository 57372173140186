#blockly-mobile-menu {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 20;
	color: #777;
	font-size: 1.3em;
	cursor: pointer;
}

.blocks-container {
	width: calc(50% - 25px - 20px);
	height: 100%;
	margin-left: 31px;
}

/* Categories bar */
.blocklyToolboxDiv {
	background-color: rgba(0, 0, 0, 0.04);
	z-index: 10;
}

/* Category label */
.blocklyTreeRow {
	margin: 3px;
	padding: 20px !important;
	background-color: transparent !important;
	cursor: pointer;
}

.blocklyTreeIcon {
	display: none !important;
}

.blocklyTreeLabel {
	display: inline-flex;
	align-items: center;
}

#blockly-0\.label {
	color: #4c97ff;
}

#blockly-1\.label {
	color: #59c059;
}

#blockly-2\.label {
	color: #ff9f29;
}

#blockly-3\.label {
	color: #ff6680;
}

#blockly-4\.label {
	color: #888888;
}

.blocklyTreeLabel::before {
	font-family: 'Font Awesome 5 Free';
	font-size: 2.2em;
	font-weight: bold;
	margin-right: .4em;
}

#blockly-0\.label::before {
	content: '\f0b2';
}

#blockly-1\.label::before {
	content: '\f1b3';
}

#blockly-2\.label::before {
	content: '\f074';
}

#blockly-3\.label::before {
	content: '\f6be';
}

#blockly-4\.label::before {
	content: '\f492';
}

#blockly-0 {
	animation: wiggle 6s infinite;
}

#blockly-1 {
	animation: wiggle 6s infinite;
	animation-delay: 70ms;
}

#blockly-2 {
	animation: wiggle 6s infinite;
	animation-delay: 140ms;
}

#blockly-3 {
	animation: wiggle 6s infinite;
	animation-delay: 210ms;
}

#blockly-4 {
	animation: wiggle 6s infinite;
	animation-delay: 280ms;
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   95% { transform: rotate(0deg); }
   96% { transform: rotate(3deg); }
   97% { transform: rotate(-3deg); }
   98% { transform: rotate(3deg); }
   99% { transform: rotate(-3deg); }
  100% { transform: rotate(0deg); }
}

/* Toolbox container */
.blocklyFlyout {
	border: #aaa 2px dashed;
	border-radius: 10px;
	box-sizing: border-box;
	height: calc(100% - 10px);
	/* Makes issues when adding blocks from the toolbox (offset between block and pointer) */
	/* top: 5px;
	left: 5px;
	bottom: 5px;
	transform: none !important; */
}

.blocklyFlyoutBackground {
	fill: #f5f5f5;
	fill-opacity: 1.0;
}

.leftRightMutatorButton {
	opacity: 0.5;
}

.leftRightMutatorButton:hover {
	opacity: 1.0;
}
